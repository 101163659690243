import React from 'react';
import './LoadingOverlay.css';

const LoadingOverlay = () => (
  <div className="loading-overlay">
    <div className="loading-content">
      <div className="fall-scene">
        <div className="scene-background"></div>
        <div className="leaves">
          {[...Array(15)].map((_, i) => (
            <div key={i} className={`leaf leaf-${i + 1}`}></div>
          ))}
        </div>
        <div className="pond"></div>
        <div className="duck-container">
          <img src="/duck-logo.png" alt="Rubber Duck" className="duck-logo" />
        </div>
      </div>
      <p className="fall-message">Quacking up some autumn insights for you...</p>
    </div>
  </div>
);

export default LoadingOverlay;