// firebaseConfig.js
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyCcJx6nqFfYEqNiIHMmYOzU7xPHuKMUnY0",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "decisive-fold-436618-h2.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "decisive-fold-436618-h2",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "decisive-fold-436618-h2.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "32723243782",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:32723243782:web:39bec5a7d243f6798d4487"
};

// Initialize Firebase
let app; // Declare app variable
let db; // Declare db variable

try {
  if (!getApps().length) {
    app = initializeApp(firebaseConfig);
  } else {
    app = getApps()[0];
  }
  db = getFirestore(app);
} catch (error) {
  console.error("Error initializing Firebase:", error);
  throw new Error("Failed to initialize Firebase. Please check your configuration.");
}

// Validate database connection
const validateDbConnection = async () => {
  try {
    // Try to access a collection to verify connection
    const testCollection = collection(db, 'Search_Record');
    if (!testCollection) {
      throw new Error('Failed to access Firestore collection');
    }
    return true;
  } catch (error) {
    console.error("Database connection error:", error);
    return false;
  }
};

// Function to record search data
export async function recordSearch({ 
  url,
  job_title,
  company, 
  response,
  error = null,
  userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : 'unknown',
  platform = typeof navigator !== 'undefined' ? navigator.platform : 'unknown'
}) {
  try {
    const isConnected = await validateDbConnection();
    if (!isConnected) {
      throw new Error('Database connection failed');
    }

    if (!url) {
      throw new Error('URL is required');
    }

    // Get the current hostname
    const hostname = typeof window !== 'undefined' ? window.location.hostname : 'unknown';

    const searchRecord = {
      url,
      job_title,
      company, 
      response,
      error,
      timestamp: serverTimestamp(),
      metadata: {
        userAgent,
        platform,
        environment: process.env.NODE_ENV || 'development', // This will be 'development' locally
        hostname: hostname, // This will be 'localhost' in local development
        //ipAddress: typeof window !== 'undefined' ? window.clientIp || null : null,
        //referrer: typeof document !== 'undefined' ? document.referrer || null : null,
      }
    };

    console.log('Attempting to save record with metadata:', {
      environment: searchRecord.metadata.environment,
      hostname: searchRecord.metadata.hostname
    });

    const docRef = await addDoc(collection(db, 'Search_Record'), searchRecord);
    console.log("Search record saved with ID:", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error saving search record:", error);
    console.error("Error details:", {
      message: error.message,
      code: error.code,
      stack: error.stack,
      timestamp: new Date().toISOString(),
      environment: process.env.NODE_ENV
    });
    
    return {
      success: false,
      error: error.message,
      timestamp: new Date().toISOString()
    };
  }
}

// Export the db instance for use in other parts of the application
export { db };