interface ValidationResponse {
    isValid: boolean;
    errorMsg: string; 
}

const ValidateInput = (url: string): ValidationResponse => {
    let val = true;
    let msg = ''; 

  // Check if url is defined and not empty
  if (!url || typeof url !== "string" || url.trim() === "") {
    msg = "Please enter a valid URL";
    val = false;
  } else if (!validateUrl(url)) {
    msg = "This does not look like a valid URL?";
    val = false;
  } else if (!isValidJobUrl(url)) {
    msg = "This URL does not appear to be a job posting?";
    val = false;
  } else if (isRestrictedDomain(url)) {
    msg = 
      "We are not able to process job posts from LinkedIn or any sites that require login. Can you find the original source?";
    val = false;
  }

  return {isValid: val, errorMsg: msg} as ValidationResponse;
};

const isValidJobUrl = (url: string) => {
  if (typeof url !== "string") return false;
  const keywords = [
    "job",
    "role",
    "detail",
    "candidate",
    "apply",
    "position",
    "career",
    "id",
  ];
  const lowercaseUrl = url.toLowerCase();
  return keywords.some((keyword) => lowercaseUrl.includes(keyword));
};

const isRestrictedDomain = (url: string) => {
  if (typeof url !== "string") return false;
  const restrictedDomains = [
    ".linkedin.com",
    ".glassdoor.com",
    ".joinhandshake.com",
    ".wellfound.com",
    ".indeed.com",
    ".lhh.com",
  ];
  return restrictedDomains.some((domain) => url.includes(domain));
};

const validateUrl = (url: string) => {
    if (typeof url !== 'string') return false;
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

export default ValidateInput;
